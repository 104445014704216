import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { ConfirmDialogNotificationComponent } from './confirm-dialog-notification.component';



export interface AlertConfig {
    title?: string,
    content?: string,
    contentHtml?: string,
    close?: string
}

export interface ConfirmConfig {
    title?: string,
    content?: string,
    contentHtml?: string,
    confirm?: string,
    cancel?: string
}

/*
    Dialog service for alert or confirmation popup dialogs
    loosely based on  https://stackblitz.com/edit/simplifying-material-dialog-3?embed=1&file=app/app.component.ts

    example usage
    this.dialogService.confirm({ contentHtml: 'do you think <b>it will work?</b>', confirm:'Of course' }).subscribe(value =>
      this.dialogService.alert({ title: 'it works! ' + String(value), content: 'I knew it' })
    )
*/

@Injectable()
export class ConfirmDialogService {

    constructor(
        private dialog: MatDialog,
        private translate: TranslateService
    ) { }

    alert(alert: AlertConfig) {

        if (!alert.close) {
            alert.close = this.translate.instant('kailo.dialog.button.close')
        }

        const dialogRef = this.dialog.open(ConfirmDialogNotificationComponent,
            { width: '500px', data: alert }
        )
        return dialogRef.afterClosed()
    }

    confirm(confirm: ConfirmConfig) {

        if (!confirm.cancel) {
            confirm.cancel = this.translate.instant('kailo.dialog.button.cancel')
        }
        if (!confirm.confirm) {
            confirm.confirm = this.translate.instant('kailo.dialog.button.ok')
        }

        const dialogRef = this.dialog.open(ConfirmDialogNotificationComponent,
            { width: '500px', data: confirm }
        )
        return dialogRef.afterClosed().pipe(
            map(Boolean)
        )
    }

    confirmSaveCancel(confirm: ConfirmConfig) {

        if (!confirm.cancel) {
            confirm.cancel = this.translate.instant('kailo.dialog.button.cancel')
        }
        if (!confirm.confirm) {
            confirm.confirm = this.translate.instant('kailo.dialog.button.save')
        }

        const dialogRef = this.dialog.open(ConfirmDialogNotificationComponent,
            { width: '500px', data: confirm, closeOnNavigation: false }
        )
        return dialogRef.afterClosed().pipe(
            map(Boolean)
        )
    }
}
