import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ChangePasswordModel, ForgotPasswordClient, ResetPasswordModel, ResultDTO } from '@shared/http-clients/http-clients';

@Injectable()
export class ResetPasswordDataService {

  private _isResettingPassword$ = new BehaviorSubject<boolean>(false);
  readonly isResettingPassword$ = this._isResettingPassword$.asObservable();

  constructor(
    private forgotPasswordClient: ForgotPasswordClient,
  ) { }
  
  resetPassword(model: ResetPasswordModel): Observable<ResultDTO> {
    this._isResettingPassword$.next(true);

    return this.forgotPasswordClient.patch(model).pipe(
      take(1),
      finalize(() => this._isResettingPassword$.next(false))
    );
  }

  changePassword(model: ChangePasswordModel): Observable<ResultDTO> {
    this._isResettingPassword$.next(true);

    return this.forgotPasswordClient.changePassword(model).pipe(
      take(1),
      finalize(() => this._isResettingPassword$.next(false))
    );
  }
}
