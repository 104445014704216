import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, ErrorHandler, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppState, reducers, metaReducers, selectRouterState } from './core.state';
import { ValidGuard } from '@core/guards/valid-guard';
import { TitleService } from './title/title.service';
import { ROUTE_ANIMATIONS_ELEMENTS, routeAnimations } from './animations/route.animations';
import { AnimationsService } from './animations/animations.service';
import { AppErrorHandler } from './error-handler/app-error-handler.service';
import { CustomSerializer } from './router/custom-serializer';
import { LocalStorageService } from './local-storage/local-storage.service';
import { HttpErrorInterceptor } from './http-interceptors/http-error.interceptor';
import { GoogleAnalyticsEffects } from './google-analytics/google-analytics.effects';
import { NotificationService } from './notifications/notification.service';
import { SettingsEffects } from './settings/settings.effects';
import { selectSettingsLanguage, selectEffectiveTheme, selectSettingsStickyHeader } from './settings/settings.selectors';
import { JwtInterceptor } from './http-interceptors/jwt.interceptor';
import { UserInfoClient, API_BASE_URL, UserConsentClient, SexesClient, TreatmentTypesFilterClient, BookingStatusClient, TreatmentProviderNetworkClient, AssignmentClient, RoleClient, SelfRegistrationClient, TermsAndConditionsClient, MitIdClient } from '../shared/http-clients/http-clients';
import { AgmCoreModule } from '@agm/core';
import { appInitializerFactory } from './app-initializer';
import { CoreDataService } from './core-data.service';
import { RouterDataService } from './router/router-data.service';
import { AuthService } from './auth/auth.service';
import { SessionService } from './session/session.service';
import { NgxPermissionsModule } from 'ngx-permissions';

import { environment } from '../../environments/environment';
import { AuthGuard } from './guards/auth-guard';
import { tenantInitializerFactory } from './tenant/tenant.initializer';
import { TenantService } from './tenant/tenant.service';
import { PageLoaderService } from './page-loader/page-loader.service';
import { LanguageInterceptor } from './http-interceptors/language.interceptor';
import { DialogNotificationComponent } from './notifications/dialog-notification/dialog-notification.component';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PwaService } from './pwa/pwa.service';
import { VideoFeatureGuard } from './guards/video-feature.guard';
import { VideoCallHelperService } from './helpers/video-call.helper.service';
import { ConfirmDialogNotificationComponent } from './notifications/confirm-dialog-notification/confirm-dialog-notification.component';

export {
  TitleService,
  routeAnimations,
  AppState,
  LocalStorageService,
  ROUTE_ANIMATIONS_ELEMENTS,
  AnimationsService,
  ValidGuard,
  AuthGuard,
  selectRouterState,
  NotificationService,
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader,
  CoreDataService,
  RouterDataService,
  AuthService,
  SessionService,
  PageLoaderService,
  VideoFeatureGuard
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json'
  );
}

@NgModule({
  imports: [
    // angular
    CommonModule,
    HttpClientModule,

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([SettingsEffects, GoogleAnalyticsEffects]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
        name: 'Kailo'
      }),

    // 3rd party
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),

    NgxPermissionsModule.forRoot(),
   
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),

    // angular material
    MatDialogModule,
    MatButtonModule,
    FontAwesomeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: API_BASE_URL, useValue: environment.apiBaseUrl },
    { provide: APP_INITIALIZER, useFactory: tenantInitializerFactory, deps: [TenantService], multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [Injector], multi: true },
    AppErrorHandler,
    UserConsentClient,
    UserInfoClient,
    SexesClient,
    TreatmentTypesFilterClient,
    BookingStatusClient,
    TreatmentProviderNetworkClient,
    AssignmentClient,
    RoleClient,
    SelfRegistrationClient,
    PwaService,
    VideoCallHelperService,
    TermsAndConditionsClient,
    MitIdClient
  ],
  exports: [TranslateModule],
  declarations: [DialogNotificationComponent, ConfirmDialogNotificationComponent]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
