import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { tap, filter, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { SessionService } from '@core/session/session.service';
import { isNullOrWhitespace } from '@core/helpers/common-helpers';

@Injectable()
export class GoogleAnalyticsEffects {
  constructor(
    private router: Router,
    // private session: SessionService,
  ) { }

  pageView = createEffect(
    () => () =>
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd && environment.production && !isNullOrWhitespace(environment.googleAnalyticsCode)),
        tap((event: NavigationEnd) => {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        })
      ),
    { dispatch: false }
  );

  // userId = createEffect(
  //   () => () =>
  //     this.session.userInfo$.pipe(
  //       filter(user => user && user.id && !isNullOrWhitespace(environment.googleAnalyticsCode)),
  //       distinctUntilChanged((x, y) => x.id === y.id),
  //       tap(user => (<any>window).ga('set', 'userId', user.id))
  //     ),
  //   { dispatch: false }
  // )
}
