<div [class]="'theme-wrapper ' + (theme$ | async)">

  <mat-sidenav-container>

    <mat-sidenav #sidenav mode="push">
      <div class="branding">
        <img [src]="logo" />
      </div>
      <mat-nav-list>
        <ng-template *ngFor="let item of navigationSideMenu" [ngxPermissionsOnly]="item.ngxPermissionsOnly"
          [ngxPermissionsExcept]="item.ngxPermissionsExcept">
          <a mat-list-item (click)="sidenav.close()" [routerLink]="[item.link]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact : item.id === 'homeMenu' ? true :  false} ">
            {{item.label | translate}}
          </a>
        </ng-template>
      </mat-nav-list>
    </mat-sidenav>

    <div class="wrapper" [class.small-device-content-margin]="true">
      <!-- Fixed loader under toolbar -->
      <div class="fixed-page-progress-bar-loader-wrapper">
        <mat-progress-bar mode="indeterminate" *ngIf="isPageLoading$ | async"></mat-progress-bar>
      </div>

      <div class="toolbar" *ngIf="!(shouldHideToolbar$ | async)"
        [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'"
        [class.mat-elevation-z4]="(stickyHeader$ | async)">
        <mat-toolbar >
          <button mat-icon-button class="d-md-none" (click)="sidenav.open()">
            <fa-icon icon="bars"></fa-icon>
          </button>
         
          <span routerLink="" class="branding spacer center d-inline d-sm-none">
          </span>
          <span style="cursor: default;" class="branding spacer center d-none d-sm-inline d-md-none">
            <img data-test="header-img-xs-logo" routerLink="" style="cursor: pointer;" [src]="logo" />
          </span>
          <span style="cursor: default;" class="branding spacer d-none d-md-inline">
            <img data-test="header-img-lg-logo" routerLink="" style="cursor: pointer;" [src]="logo" />
          </span>
          <span class="d-none d-md-inline">
            <ng-template *ngFor="let item of navigation" [ngxPermissionsOnly]="item.ngxPermissionsOnly"
              [ngxPermissionsExcept]="item.ngxPermissionsExcept">
              <a *ngIf="!item.submenus" mat-button class="nav-button" [id]="item.id" [routerLink]="[item.link]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact : item.id === 'homeMenu' ? true :  false} "> 
                {{item.label | translate}}
              </a>
              <ng-template *ngIf="item.submenus?.length > 0" [ngxPermissionsOnly]="item.ngxPermissionsOnly"
                [ngxPermissionsExcept]="item.ngxPermissionsExcept">
                <a mat-button class="nav-button" [matMenuTriggerFor]="submenu" [id]="item.id"
                  [matMenuTriggerData]="{menudata: item}">
                  {{item.label | translate}}
                </a>
              </ng-template>
            </ng-template>
          </span>

          <mat-menu #submenu class="submenu">
            <ng-template matMenuContent let-menudata="menudata">
              <ng-template *ngFor="let i of menudata.submenus" [ngxPermissionsOnly]="i.ngxPermissionsOnly"
                [ngxPermissionsExcept]="i.ngxPermissionsExcept">
                <div class="row">
                    <a mat-button [routerLink]="[i.link]" [id]="i.id" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact : true} " class="admin-panel-item">
                    <span class="submenu-items">
                      {{i.label | translate}}
                    </span>
                  </a>
                </div>
              </ng-template>
            </ng-template>
          </mat-menu>

          <button mat-button mat-stroked-button color="accent" *ngIf="!(isAuthenticated$ | async)"
            [routerLink]="['login']" routerLinkActive="active">
            {{ 'kailo.menu.login' | translate }}
          </button>

          <button *ngIf="(isAuthenticated$ | async)" mat-button [matMenuTriggerFor]="toolbarUserMenu">
            <ng-container *ngIf="userProfilePicture$ | async; else noProfilePicture">
              <img [src]="userProfilePicture$ | async" class="avatar-img" />
            </ng-container>
            <ng-template #noProfilePicture>
              <fa-icon *ngIf="!(userProfilePicture$ | async)" icon="user-circle"></fa-icon>
            </ng-template>
            <span class="m-1 d-none d-sm-inline">{{ (userInfo$ | async)?.fullName }}</span>
            <fa-icon icon="chevron-down" size="xs" class="avatar-chevron-down-icon"></fa-icon>
          </button>
          <mat-menu #toolbarUserMenu="matMenu" >
            <!-- <button mat-menu-item>
              <mat-icon>
                <fa-icon icon="power-off"></fa-icon>
              </mat-icon>
              <span>{{ 'kailo.menu.account.information' | translate }}</span>
            </button> -->
            <!-- <mat-divider></mat-divider> -->
            <div class="flagContainer">
              <span class="flag clickable" (click)="onLanguageSelect('da')">
                <img [src]="daFlag" style="width: 20px;" />
              </span>
              <span class="flag clickable" (click)="onLanguageSelect('en')">
                <img [src]="enFlag" style="width: 20px;" />
              </span>
              <span class="flag clickable" (click)="onLanguageSelect('de')">
                <img [src]="deFlag" />
              </span>
              <span class="flag clickable" (click)="onLanguageSelect('mk')">
                <img [src]="mkFlag" style="width: 20px;" />
              </span>
              <span class="flag clickable" (click)="onLanguageSelect('sq')">
                <img [src]="sqFlag" style="width: 20px;" />
              </span>
            </div>
            <button mat-menu-item routerLink="settings">
              <span>{{ 'kailo.menu.settings' | translate }}</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="onLogoutClick()">
              <span>{{ 'kailo.menu.logout' | translate }}</span>
            </button>
          </mat-menu>
        </mat-toolbar>
      </div>

      <div [ngClass]="contentClass$ | async" [style.margin-top]="contentMarginTop$ | async" [@routeAnimations]="routeTitle$ | async">
        <!-- loader bellow toolbar -->
        <div class="page-progress-bar-loader-wrapper">
          <mat-progress-bar mode="indeterminate" *ngIf="isPageLoading$ | async"></mat-progress-bar>
        </div>
        <router-outlet #o="outlet" (activate)="onRouterOutletActivate(o)"></router-outlet>
      </div>

      <div class="footer" *ngIf="o.isActivated && !(shouldHideFooter$ | async)">
        <div class="row">
          <div class="col-4 pt-2 phone-number-text"> 
            <ng-container *ngIf="phoneNumber">
              {{'kailo.phone-number.title' | translate}} : {{phoneNumber}} 
            </ng-container>
          </div>
          <div class="col-sm-4 p-2">
            &#169; <span class="year">{{year}}</span> - Powered by Kailo {{version}}
            <span *ngIf="!isProd">[{{envName}}]</span>
          </div>
          <div class="col-sm-4 links">
            <a (click)="navigateToTerms()">{{'kailo.terms-conditions.title' | translate}}</a>
          </div>
        </div>
      </div>

    </div>

  </mat-sidenav-container>

</div>