import { Injectable, Injector } from '@angular/core';
import { CoreServiceBase } from '@core/bases/core-service-base';
import { BehaviorSubject } from 'rxjs';
import { ChangePasswordModel, ResetPasswordModel } from '@shared/http-clients/http-clients';
import { ResetPasswordDataService } from './resetpassworddata.service';
import { Router } from '@angular/router';
import { EventEmitter } from 'protractor';

@Injectable()
export class ResetPasswordService extends CoreServiceBase {

  private _hasBackToLogin$ = new BehaviorSubject<boolean>(false);
  readonly hasBackToLogin$ = this._hasBackToLogin$.asObservable();

  constructor(
    injector: Injector,
    public router: Router,
    private resetPasswordDataService: ResetPasswordDataService
  ) {
    super(injector);
  }

  set(hasBackToLogin?: boolean) {
    if (!!hasBackToLogin) {
      this._hasBackToLogin$.next(hasBackToLogin);
    }
  }

  resetPassword(model: ResetPasswordModel) {
    this.resetPasswordDataService.resetPassword(model).subscribe(() => {
      const message = this.translate.instant('kailo.login.reset-password.success');
      this.notify.success(message);
      this.router.navigate(['login']);
    });
  }

  setPassword(model: ResetPasswordModel) {
    return this.resetPasswordDataService.resetPassword(model);
  }

  changePassword(model: ChangePasswordModel) {
    return this.resetPasswordDataService.changePassword(model);
  }
}
