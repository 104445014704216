import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class VideoCallHelperService {
  isInVideoCallRoom$ = new BehaviorSubject<boolean>(false);

  setIsInVideoCallRoom(isInVideoCallRoom: boolean) {
    this.isInVideoCallRoom$.next(isInVideoCallRoom);
  }
}
