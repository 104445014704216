const packageJson = require('../../../../package.json');

export const environment = {
  appName: 'Kailo',
  envName: 'STAGE',
  production: true,
  test: false,
  i18nPrefix: '',
  apiBaseUrl: 'https://api.kailo.dk',
  googleAnalyticsCode: '',
  googleClientId: "316244877972-j8n1s5v5n622cjkhhg7f7h5alse276t2.apps.googleusercontent.com",
  googleApiKey: "AIzaSyCYdnjAhWAI7t8d5l130LGUhEkHbG2J3Ow",
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress']
  }
};
