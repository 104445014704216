import { Injector } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { PwaService } from './pwa/pwa.service';

export function appInitializerFactory(injector: Injector) {
  return () => {
    return new Promise<boolean>((resolve, reject) => {
      const authService = injector.get(AuthService);
      const pwaService = injector.get(PwaService);

      pwaService.init();

      authService.init().then(result => {
        if (!result) authService.logout();
        resolve(true);
      }).catch(() => {
        authService.logout();
        resolve(true);
      });
    });
  };
}
