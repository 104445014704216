import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TenantService } from '@core/tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class VideoFeatureGuard implements CanActivate {

  constructor(private tenantService: TenantService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.tenantService.currentTenant.useVideoFeature;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
