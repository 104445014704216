import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterDataService {
  private _activatedRouteData$ = new BehaviorSubject<any>({
    title: '',
    hideFooter: false,
    hideToolbar: true
  }); // todo create a model for activated route data

  readonly activatedRouteData$ = this._activatedRouteData$.asObservable();

  setActivatedRouteData(data) {
    data.hideFooter = data.hideFooter ? true : false;
    data.hideToolbar = data.hideToolbar ? true : false;
    this._activatedRouteData$.next(data);
  }
}
