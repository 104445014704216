export function extractName(identity: string): string {
  const lastIndex = identity.lastIndexOf(" ");
  return new String(identity).substring(0, lastIndex);
}

export function extractId(identity: string): string {
  const lastIndex = identity.lastIndexOf(" ");
  return new String(identity).substring(lastIndex + 1);
}

export function extractInitials(fullName: string): string {
  const firstNameLastName = fullName.split(' ')
  let initials = firstNameLastName.length >= 1 ? firstNameLastName[0]?.slice(0, 1) : ''
  initials = initials + (firstNameLastName.length >= 2 ? firstNameLastName[1]?.slice(0, 1) : "").toString()
return initials
}