export const Roles = {
  Patient: 'Roles.PATIENT',
  TreatmentProviderEmployee: 'Roles.TREATMENT_PROVIDER_EMPLOYEE',
  TreatmentProviderRepresentative: 'Roles.TREATMENT_PROVIDER_REPRESENTATIVE',
  TreatmentProviderNetwork: 'Roles.TREATMENT_PROVIDER_NETWORK',
  InsuranceCompany: 'Roles.INSURANCE_COMPANY',
  Admin: 'Roles.ADMIN',
  NetworkAdmin: 'Roles.NETWORK_ADMIN',
  Nurse: 'Roles.NURSE', 
  IT: 'Roles.IT'
};

export const RoleCodes = {
  Patient: 'PATIENT',
  TreatmentProviderEmployee: 'TREATMENT_PROVIDER_EMPLOYEE',
  TreatmentProviderRepresentative: 'TREATMENT_PROVIDER_REPRESENTATIVE',
  TreatmentProviderNetwork: 'TREATMENT_PROVIDER_NETWORK',
  InsuranceCompany: 'INSURANCE_COMPANY',
  Admin: 'ADMIN',
  NetworkAdmin: 'NETWORK_ADMIN',
  Nurse: 'NURSE', 
  IT: 'IT'
}
