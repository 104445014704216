<h2 mat-dialog-title *ngIf="dialog.title">
  {{ dialog.title }}
</h2>
<mat-dialog-content *ngIf="dialog.content || dialog.contentHtml">
  <span *ngIf="dialog.content">{{dialog.content}}</span>
  <span *ngIf="dialog.contentHtml" [innerHtml]="dialog.contentHtml"></span>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end;">
  <button *ngIf="dialog.cancel" type="button" mat-button color="default" (click)="dialogRef.close(false)">
    {{ dialog.cancel }}
  </button>
  <button *ngIf="dialog.close" type="button" mat-flatbutton (click)="dialogRef.close(false)" color="primary">
    {{ dialog.close }}
  </button>
  <button *ngIf="dialog.confirm" color="accent" type="button" mat-flat-button (click)="dialogRef.close(true)">
    {{ dialog.confirm }}
  </button>
</mat-dialog-actions>