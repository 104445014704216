import { Injectable, Injector } from '@angular/core';
import { CoreServiceBase } from '@core/bases/core-service-base';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SexModel } from '@shared/http-clients/http-clients';

@Injectable()
export class SexService extends CoreServiceBase {

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  extractSex$(isFemale: boolean): Observable<SexModel> {
    return combineLatest([
      this.coreData.sexes$,
      this.translate.get('Male'),
      this.translate.get('Female')
    ]).pipe(take(1),
      map(([sexes, maleName, femaleName]) => {
        if (isFemale) {
          return sexes.filter(s => s.name === femaleName)[0];
        } else {
          return sexes.filter(s => s.name === maleName)[0];
        }
      })
    )
  }
}
