import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { NotificationService } from '../notifications/notification.service';
import { ApiException, ResultModel } from '@shared/http-clients/http-clients';
import { isNullOrWhitespace } from '@core/helpers/common-helpers';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private notificationsService: NotificationService) {
    super();
  }

  handleError(error: Error | HttpErrorResponse | ApiException | string | ResultModel | any) {
    let isFatal: boolean;

    if (error instanceof HttpErrorResponse) {
      isFatal = error.status === 500
    }

    if (error instanceof ApiException && error.message === 'Unauthorized') {
      // this should be only if refresh token is expired
    } else if (typeof error === 'string') {
      this.notificationsService.error(error);
    } else if (error instanceof ResultModel && !error.succeeded) {
      isFatal = isFatal || error.statusCode === '500';
      this.notificationsService.error(error.errorMessage);
    } else {
      const resultModel = ResultModel.fromJS(error);
      isFatal = isFatal || resultModel.errorCode === '500';
      let displayMessage = resultModel.errorMessage ?? 'An error occurred.';

      if (!environment.production) {
        displayMessage += ' See console for details.';
      }

      if (environment.production && !isNullOrWhitespace(environment.googleAnalyticsCode)) {
        (<any>window).ga('send', 'exception', {
          'exDescription': 'Hidden',
          'exFatal': isFatal
        });
      }

      this.notificationsService.error(displayMessage);
    }

    return super.handleError(error);
  }
}
