import {
  connect, ConnectOptions, LocalTrack, Room, LocalVideoTrack,
  LocalAudioTrack, createLocalAudioTrack, createLocalVideoTrack,
  createLocalTracks, RemoteParticipant, Track, LocalParticipant
} from 'twilio-video';
import { Injectable, Injector } from '@angular/core';
import { VideoClient } from '@shared/http-clients/http-clients';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { CoreServiceBase } from '@core/bases/core-service-base';
import { take, switchMap, finalize, tap, map, filter, distinctUntilChanged, catchError } from 'rxjs/operators';
import { ParticipantDetailProperties } from '@features/video-chat/model/participant-detail-properties';
import { BookingPageDetailsModel, BookingsClient } from '../../../shared/http-clients/http-clients';
import { extractName, extractId } from '@features/video-chat/helpers/video-identity-helper';
import { Router } from '@angular/router';

@Injectable()
export class VideoChatService extends CoreServiceBase {

  private _activeRoom$ = new BehaviorSubject<Room>(undefined);
  readonly activeRoom$ = this._activeRoom$.asObservable();

  private _pinnedParticipants$ = new BehaviorSubject<RemoteParticipant[]>([]);
  readonly pinnedParticipants$ = this._pinnedParticipants$.asObservable();

  private _participants$ = new BehaviorSubject<ParticipantDetailProperties[]>([]);// every time a person is added to the list, add it to this list, from participant detail subscribe and update participant data 
  readonly participants$ = this._participants$.asObservable();

  private _isJoiningRoom$ = new BehaviorSubject<boolean>(false);
  readonly isJoiningRoom$ = this._isJoiningRoom$.asObservable();

  private _isInitializing$ = new BehaviorSubject<boolean>(true);
  readonly isInitializing$ = this._isInitializing$.asObservable();

  private _isDrawerOpenned$ = new BehaviorSubject<boolean>(true);
  readonly isDrawerOpenned$ = this._isDrawerOpenned$.asObservable();

  private _isPreviewingSelf$ = new BehaviorSubject<boolean>(true);
  readonly isPreviewingSelf$ = this._isPreviewingSelf$.asObservable();

  private _isMuted$ = new BehaviorSubject<boolean>(false);
  readonly isMuted$ = this._isMuted$.asObservable();

  private _isPresenting$ = new BehaviorSubject<boolean>(false);
  readonly isPresenting$ = this._isPresenting$.asObservable();

  private _localTracks$ = new BehaviorSubject<LocalTrack[]>(undefined);
  readonly localTracks$ = this._localTracks$.asObservable();

  private _isLeavingRoom$ = new BehaviorSubject<boolean>(false);
  readonly isLeavingRoom$ = this._isLeavingRoom$.asObservable();

  readonly videoTrack$ = this.localTracks$.pipe(
    map(tracks => {
      if (!tracks || !tracks.length) return null;

      var videoTracks = tracks.filter(track => track.kind === 'video');

      if (!videoTracks || !videoTracks.length) return null;

      return videoTracks[0] as LocalVideoTrack
    }),
    distinctUntilChanged((x, y) => x && y && x.id === y.id)
  );

  constructor(
    injector: Injector,
    private videoClient: VideoClient,
    private bookingsClient: BookingsClient,
    private router: Router
  ) {
    super(injector);
  }

  addParticipant(participant: ParticipantDetailProperties) {
    const participants = this._participants$.getValue();
    this._participants$.next([...participants, participant]);
  }

  removeParticipant(participantSid: string) {
    const participants = this._participants$.getValue().filter(x => x.sid !== participantSid);
    this._participants$.next(participants);
  }

  updateParticipant(sid: string, action: 'audioOn' | 'audioOff' | 'videoOn' | 'videoOff') {
    const participants = this._participants$.getValue();
    const partcipant = participants.find(x => x.sid === sid)
    if (partcipant) {
      switch (action) {
        case 'audioOn':
        case 'audioOff':
          partcipant.isMuted = action === 'audioOff' ? true : false
          break
        case 'videoOn':
        case 'videoOff':
          partcipant.cameraShown = action === 'videoOn' ? true : false
          break
      }

      this._participants$.next(participants);
    }
  }


  mapRemoteParticipantToParticipantData(participant: RemoteParticipant): ParticipantDetailProperties {
    const participantName = extractName(participant.identity)
    const participantId = extractId(participant.identity)

    const participantDetailProperties: ParticipantDetailProperties = new ParticipantDetailProperties();
    participantDetailProperties.fullname = participantName;
    const firstNameLastName = participantName.split(' ');
    participantDetailProperties.initials = firstNameLastName.length >= 1 ? firstNameLastName[0]?.slice(0, 1) : '';
    participantDetailProperties.initials = participantDetailProperties.initials + (firstNameLastName.length >= 2 ? firstNameLastName[1]?.slice(0, 1) : "").toString();
    participantDetailProperties.participantId = Number(participantId);
    participantDetailProperties.sid = participant.sid;
    participantDetailProperties.isMuted = participant.audioTracks? true : false;
 participantDetailProperties.cameraShown = participant.videoTracks? true : false;
    return participantDetailProperties;
  }

  mapLocalParticipantToParticipantData(participant: LocalParticipant): ParticipantDetailProperties {
    const participantName = extractName(participant.identity)
    const participantId = extractId(participant.identity)

    const participantDetailProperties: ParticipantDetailProperties = new ParticipantDetailProperties()
    participantDetailProperties.fullname = participantName
    const firstNameLastName = participantName.split(' ')
    participantDetailProperties.initials = firstNameLastName.length >= 1 ? firstNameLastName[0]?.slice(0, 1) : ''
    participantDetailProperties.initials = participantDetailProperties.initials + (firstNameLastName.length >= 2 ? firstNameLastName[1]?.slice(0, 1) : "").toString()
    participantDetailProperties.participantId = Number(participantId)
    participantDetailProperties.sid = participant.sid
    return participantDetailProperties
  }

  fetchBooking(id: number): Observable<BookingPageDetailsModel> {
    return this.bookingsClient.getBooking(id);
  }


  joinOrCreateRoom(bookingId: number, tracks: LocalTrack[]): Observable<Room> {
    this._isJoiningRoom$.next(true);
    const connectOptions: ConnectOptions = {
      tracks
    };

    return this.videoClient.getToken(bookingId).pipe(
      take(1),
      switchMap(token => from(connect(token, connectOptions))),
      catchError(error => {
        this.router.navigate(['']);
        this.notify.error(error.message);
        return of(null)
      }),
      tap(room => this._activeRoom$.next(room)),
      finalize(() => this._isJoiningRoom$.next(false))
    );
  }

  setActiveRoom(room: Room) {
    this._activeRoom$.next(room);
  }

  setIsInitializing(value: boolean) {
    this._isInitializing$.next(value);
  }

  onRoomDisconnected() {
    this.unpublishVideoTrack();
    this.unpublishAudioTrack();
  }

  detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.stop();
      track.detach().forEach(el => el.remove());
      track.mediaStreamTrack.stop();
      track.disable();
      this.removeLocalTrack(track);
    }
  }

  addLocalTrack(track: LocalTrack) {
    const localTracks = this._localTracks$.getValue();
    this._localTracks$.next([...localTracks, track]);
  }

  removeLocalTrack(track: LocalTrack) {
    const localTracks = this._localTracks$.getValue().filter(x => x.id !== track.id);
    this._localTracks$.next(localTracks);
  }

  unpublishVideoTrack() {
    const room = this._activeRoom$.getValue();

    room?.localParticipant?.videoTracks?.forEach(publication => {
      this.detachLocalTrack(publication.track);
      publication.unpublish();
    });
  }

  publishVideoTrack(deviceId?: string) {
    const room = this._activeRoom$.getValue();
  
    return from(createLocalVideoTrack({ deviceId })).pipe(
      take(1),
      switchMap(localVideoTrack => from(room.localParticipant?.publishTrack(localVideoTrack))),
      tap(
        publication => this.addLocalTrack(publication.track),
        error => {
          this._isInitializing$.next(false);
          this._isPreviewingSelf$.next(false);
        }
      )
    );
  }

  unpublishAudioTrack() {
    const room = this._activeRoom$.getValue();

    room?.localParticipant?.audioTracks?.forEach(publication => {
      this.detachLocalTrack(publication.track);
      publication.unpublish();
    });
  }

  publishAudioTrack(deviceId?: string) {
  const room = this._activeRoom$.getValue();

  return from(createLocalAudioTrack({ deviceId })).pipe(
    take(1),
    switchMap(localAudioTrack => from(room.localParticipant?.publishTrack(localAudioTrack))),
    tap(
      publication => this.addLocalTrack(publication.track),
      error => {
        this._isMuted$.next(true);
      }
    )
  );
  }

  private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
    return !!track
      && ((track as LocalAudioTrack).detach !== undefined
        || (track as LocalVideoTrack).detach !== undefined);
  }

  initializeLocalTracks(): Observable<LocalTrack[]> {
    return from(createLocalTracks({ audio: true, video: true })).pipe(
      catchError(error => {
        this._isMuted$.next(true);
        this._isPreviewingSelf$.next(false);
        this._isInitializing$.next(false);
        // TODO Log to server somehow

        return of([])
      }),
      tap(tracks => this._localTracks$.next(tracks))
    );
  }

  publishLocalTracks() {
    combineLatest([
      this.localTracks$,
      this.activeRoom$
    ]).pipe(
      filter(([localTracks, room]) => localTracks && localTracks.length && !!room),
      take(1),
      switchMap(([localTracks, room]) => from(room.localParticipant?.publishTracks(localTracks)))
    ).subscribe(publication => {
    });
  }

  toggleDrawer(open: boolean = null) {
    if (open == null) {
      const isOpenned = this._isDrawerOpenned$.getValue();
      this._isDrawerOpenned$.next(!isOpenned);
    } else {
      this._isDrawerOpenned$.next(open);
    }
  }

  togglePreviewSelf(open: boolean = null) {
    if (open == null) {
      this._isPreviewingSelf$.next(!this._isPreviewingSelf$.getValue());
    } else {
      this._isPreviewingSelf$.next(open);
    }
  }

  toggleMute(isMuted: boolean = null) {
    if (isMuted == null) {
      this._isMuted$.next(!this._isMuted$.getValue());
    } else {
      this._isMuted$.next(isMuted);
    }
  }

  kickParticipant(participant: RemoteParticipant) {
    const roomSid = this._activeRoom$.getValue().sid;
    this.videoClient.kickParticipant(roomSid, participant.sid).subscribe();
  }

  pinParticipant(participant: RemoteParticipant) {
    const pinnedParticipants = this._pinnedParticipants$.getValue();
    pinnedParticipants.push(participant);
    this._pinnedParticipants$.next(pinnedParticipants);
  }

  unpinParticipant(participant: RemoteParticipant) {
    const pinnedParticipants = this._pinnedParticipants$.getValue().filter(x => x.sid !== participant.sid);
    this._pinnedParticipants$.next(pinnedParticipants);
  }

  setIsPresenting(isPresenting: boolean) {
    this._isPresenting$.next(isPresenting);
  }

  changePriority(priority: Track.Priority) {
    const localParticipant = this._activeRoom$.getValue().localParticipant;
    this.videoTrack$.pipe(
      take(1),
      filter(x => !!x)
    ).subscribe(track => {
      this._isPresenting$.next(priority === 'high');
      localParticipant?.publishTrack(track, { priority: priority });
    })
  }


  getIsLeavingRoomValue() {
    return this._isLeavingRoom$.getValue();
  }

  emitIsLeavingRoom(value:boolean){
    this._isLeavingRoom$.next(value);
  }
}

